<!--<div *ngIf="authenticationService.localStorageItem('load') === 'S'" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; background: linear-gradient(0deg, rgba(194, 111, 3, 0.5), rgba(241, 204, 149, 0.5)); z-index: 999;">
    <div style="margin-left: 45%; margin-top: 30%;">
        <img src="../../assets/img/load_MG.gif" style="width: 4rem; height: 4rem;" alt="...">
    </div>
</div>-->
<div class="container" style="margin-top: 130px;">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)">
        <mat-icon (click)="volver()" title="Volver" style="cursor: pointer; font-size: 35px;">reply</mat-icon>
        <h2 style="margin-left: 45%; margin-top: -35px;">CONTACTOS&nbsp;&nbsp;&nbsp;&nbsp;
            <select class="custom-select" id="txtFechaI" style="width: 30%;" [(ngModel)]="mesAct" (change)="buscar()">
                <option *ngFor="let mes of meses" [value]="mes.num">{{mes.mes}}</option>
            </select>
        </h2>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;"> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_crea | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="tipo">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;"> Tipo </th>
            <td mat-cell *matCellDef="let element"> {{element.tipo}} </td>
        </ng-container>
        <ng-container matColumnDef="asunto">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;"> Asunto </th>
            <td mat-cell *matCellDef="let element"> {{element.asunto}} </td>
        </ng-container>
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;"> Nombre </th>
            <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
        </ng-container>
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;"> Teléfono </th>
            <td mat-cell *matCellDef="let element"> {{element.telefono}} </td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;">Estado</th>
            <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
        </ng-container>
        <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold;"> Acciones </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon style="cursor: pointer;" title="Ver información" (click)="dialogo('VI', element.id_consulta)">visibility</mat-icon>&nbsp;&nbsp;
                <mat-icon style="cursor: pointer;" title="Actualizar estado" (click)="dialogo('AI', element.id_consulta)">replay</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
