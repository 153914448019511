import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GeneralService } from './servicio/general.service';
import { SendEmailService } from './servicio/send-email.service';
import { StorageService } from './servicio/storage.service';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './web/home/home.component';
import { EncabezadoComponent } from './web/encabezado/encabezado.component';
import { PieComponent } from './web/pie/pie.component';
import { ContactoComponent } from './web/contacto/contacto.component';
import { EgepmeComponent } from './web/egepme/egepme.component';
import { ServiciosComponent } from './web/servicios/servicios.component';
import { NosotrosComponent } from './web/nosotros/nosotros.component';
import { LoginComponent } from './intranet/login/login.component';
import { DashboardComponent } from './intranet/dashboard/dashboard.component';
import { UsuarioComponent } from './intranet/usuario/usuario.component';
import { DialogoUsuario } from './intranet/usuario/usuario.component';
import { ConfigurarComponent } from './intranet/configurar/configurar.component';
import { ConsultaComponent } from './intranet/consulta/consulta.component';
import { DialogoConsulta } from './intranet/consulta/consulta.component';
import { SlideComponent } from './intranet/slide/slide.component';
import { DialogoSlide } from './intranet/slide/slide.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    EncabezadoComponent,
    PieComponent,
    ContactoComponent,
    EgepmeComponent,
    ServiciosComponent,
    NosotrosComponent,
    LoginComponent,
    DashboardComponent,
    UsuarioComponent,
    DialogoUsuario,
    ConfigurarComponent,
    ConsultaComponent,
    DialogoConsulta,
    SlideComponent,
    DialogoSlide
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ColorPickerModule
  ],
  entryComponents: [
    DialogoUsuario,
    DialogoConsulta,
    DialogoSlide
  ],
  providers: [GeneralService, SendEmailService, StorageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
