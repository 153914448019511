<div class="container" style="margin-top: 130px;">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)"><mat-icon (click)="volver()" title="Volver" style="cursor: pointer; font-size: 35px;">reply</mat-icon><h2 style="margin-left: 45%; margin-top: -35px;">SLIDE</h2></div>
    <div class="row">
      <div class="col text-center" style="margin-top: 40px;">
        <div *ngIf="this.urlImagen !== ''">
          <img src="{{this.urlImagen}}" style="width: 200px; height: 200px;">
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <div class="col">
        <div style="text-align: center;">
          <input type="file" style="margin-bottom: 20px;" (change)="imagen($event.target.files)">
        </div>
      </div>
    </div>
    <div class="input-group input-group-sm mb-3">
      <button *ngIf="verBoton" mat-button style="margin: 0 auto; color: white; background-color: rgb(22, 62, 85);" (click)="crear()">Agregar</button>
      <div *ngIf="!verBoton" class="d-flex justify-content-center">
        <div class="spinner-border text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let slideDat of slideIC" style="margin-top: 20px; margin-bottom: 20px;">
        <div class=col *ngFor="let slide of slideDat.slide">
          <div class="row"><div class="col text-center"><img src="{{slide.url_imagen}}" style="width: 200px; height: 200px;"></div></div>
          <div class="row"><div class="col" style="text-align: center;">{{slide.texto}}</div></div>
          <div class="row"><div class="col" style="text-align: center;"><mat-slide-toggle checked="{{slide.estado}}" (change)="estado(slide.id_slide, $event)"></mat-slide-toggle></div></div>
          <div class="row"><div class="col" style="text-align: center;"><mat-icon *ngIf="!slide.estado" style="cursor: pointer;" title="Eliminar" (click)="dialogo(slide.id_slide)">delete</mat-icon></div></div>
        </div>
    </div>
</div>
