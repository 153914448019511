<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">{{dialog.tit}}</h1>
<div mat-dialog-content>
  <!-- ********** Inicio dialogo creación/edición ********** -->
  <div *ngIf="dialog.dlg === 'CU' || dialog.dlg === 'EU'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtNombre" style="background-color: white; border: none;">Nombre</span>
      </div>
      <input type="text" class="form-control" style="width: 30%;" aria-label="Sizing example input" aria-describedby="txtNombre" [(ngModel)]="usuario.nombre">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtApellido" style="background-color: white; border: none;">Apellido</span>
      </div>
      <input type="text" class="form-control" style="width: 30%;" aria-label="Sizing example input" aria-describedby="txtApellido" [(ngModel)]="usuario.apellido">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 20%;">
        <span class="input-group-text" id="txtEmail" style="background-color: white; border: none;">Mail</span>
      </div>
      <input *ngIf="dialog.dlg === 'CU'" type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtEmail" [(ngModel)]="usuario.email">
      <div *ngIf="dialog.dlg === 'EU'">{{usuario.email}}</div>
    </div>
  </div>
  <!-- ********** Fin dialogo creación/edición ********** -->
</div>
<div mat-dialog-actions>
  <button *ngIf="dialog.dlg === 'CU'" mat-button style="background-color: #FBB03B; color: white; margin: 0 auto;" (click)="crear()">Crear Usuario</button>
  <button *ngIf="dialog.dlg === 'EU'" mat-button style="background-color: #FBB03B; color: white; margin: 0 auto;" (click)="editar()">Editar Usuario</button>
</div>
