<nav class="navbar navbar-expand-md fixed-top" name="menu" [ngStyle]="{backgroundColor: bcolorNav }" (scroll)="onWindowScroll($event);">
    <a href="https://grupopiense.com/" title="Grupo Piense" style="margin-left: 8%; margin-top: 10px;">
        <img class="site-logo" src="{{authenticationService.localStorageItem('logo')}}" alt="Grupo Piense" style="width: auto; height: 100px;" />
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div *ngIf="authenticationService.localStorageItem('login') === 'false'" class="navbar-collapse collapse order-3 dual-collapse2" id="navbarCollapse" style="margin-right: 8%; margin-top: 10px; font-weight: bold;">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item active"><a class="nav-link" href="#" routerLink="">INICIO</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="nosotros">NOSOTROS</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="servicios">SERVICIOS</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="egepme">eGe PME</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="contacto">CONTACTO</a></li>
            <li class="nav-item"><a class="nav-link" href="#" routerLink="login">LOGIN</a></li>
        </ul>
    </div>
    <div *ngIf="authenticationService.localStorageItem('login') === 'true'" class="navbar-collapse collapse order-3 dual-collapse2" id="navbarCollapse" style="margin-right: 8%; margin-top: 10px; font-weight: bold; color: white;">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" style="margin-top: 8px;">{{authenticationService.localStorageItem('nombre')}}</li>
            <li class="nav-item">
                <mat-icon (click)="signOut()" title="Cerrar sesión" style="font-size: 40px; margin-left: 2%; cursor: pointer;">clear</mat-icon>
            </li>
        </ul>
    </div>
</nav>