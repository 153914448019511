<div class="w-100" style="position: relative">
    <img class="img-fluid" src="../../../assets/img/nosotros.jpg" alt="" style="max-height: 600px; width: 100%;" />
    <h2 style="position: absolute; color: white; top: 30%; left: 40%;">¿QUIÉNES SOMOS?</h2>
</div>
<div class="w-100" style="text-align: justify; margin-top: 50px; margin-bottom: 50px;">
    <img class="rounded mx-auto d-block" src="../../../assets/img/logoGP_color.png" alt="" />
    <h2 style="color: #54595F; margin-top: 40px; margin-bottom: 20px; text-align: center;">HISTORIA</h2>
    <p style="color: #2B3861; margin-left: 5%; width: 90%;">Grupo PIENSE SpA, nace a partir de la conjunción de la amistad, confianza, formación, fortalezas y trayectoria de sus
        socios, más el interés en aportar a la mejora de los procesos de liderazgo, planificación, gestión estratégica y
        sistemas de las instituciones escolares de Chile y Latinoamérica, aspecto que de acuerdo a múltiples estudios, espacio
        que engloba una oportunidad central en el logro de resultados académicos y formativos de los estudiantes.<br><br><br>
        Su nombre refiere a las diversas acciones que creemos, deben tener mayor detenimiento al momento de definir el “que,
        por que, para que, quienes y como” de cada una de las acciones que se desarrollan en educación, buscando a través de
        este ejercicio, intencionar propósitos y procesos en la búsqueda de positivos resultados en las dimensiones, de los
        diversos modelos de gestión escolar que están siendo implementados en Chile y América Latina. En el caso de Chile la
        gestión del Liderazgo, Pedagógica. Convivencia Escolar y Resultados.</p>
</div>
<div class="w-100" style="text-align: center; color: white; background-color: #D25B33; padding-top: 50px; padding-bottom: 10px; margin-bottom: 30px;">
    <h2 style="margin-top: 40px; margin-bottom: 20px;">MISIÓN</h2>
    <p style="margin-left: 10%; width: 80%;">Mejorar los resultados académicos, formativos y de otros indicadores de calidad de
        los establecimientos educacionales clientes, a través de soluciones metodológicas y tecnológicas innovadoras, que
        fortalecen el liderazgo de los equipos directivos, la gestión estratégica, la implementación de prácticas exitosas y el
        cumplimiento de la normativa vigente.</p>
    <h2 style="margin-top: 40px; margin-bottom: 20px;">VISIÓN</h2>
    <p style="margin-left: 10%; width: 80%;">Ser considerados una institución vanguardista y líder, en el ámbito de la planificación
        y gestión estratégica en educación en Chile y Latinoamérica.</p>
    <h2 style="margin-top: 40px; margin-bottom: 20px;">VALORES INSTITUCIONALES</h2>
    <p style="margin-left: 10%; width: 80%;">
        • Trabajamos colaborativamente alineados al marco valórico de nuestros clientes.<br>
        • Ofrecemos soluciones innovadoras, eficientes y eficaces.<br>
        • Establecemos altos estándares de desempeño y responsabilidad en nuestro servicio.<br>
        • Nos guiamos por principios éticos y el resguardo de la normativa vigente.</p>
    <h2 style="margin-top: 40px; margin-bottom: 20px;">DIRECTORES</h2>
    <div class="row">
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GermanC.png" alt="" />
            <h4 style="font-weight: bold;">GERMÁN CAVADA</h4>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/CristianG.png" alt="" />
            <h4 style="font-weight: bold;">CRISTIÁN GUÍÑEZ</h4>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/CristianH.png" alt="" />
            <h4 style="font-weight: bold;">CRISTIÁN HERNÁNDEZ</h4>
        </div>
    </div>
</div>
<app-pie></app-pie>
