import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { HostListener } from "@angular/core";
import { Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'app-encabezado',
  templateUrl: './encabezado.component.html',
  styleUrls: ['./encabezado.component.scss']
})
export class EncabezadoComponent implements OnInit {
  scrHeight: any;
  scrWidth: any;
  bcolorNav = 'rgb(0, 0, 0, 0.2)';

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?)
    {
      localStorage.setItem('movil', 'false');
      this.scrHeight = window.innerHeight;
      this.scrWidth = window.innerWidth;
      localStorage.setItem('width', this.scrWidth);

      if (this.scrWidth < 1100) {
        localStorage.setItem('movil', 'true');
      }
      // console.log(this.scrHeight, this.scrWidth);
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(e) {
      let element = document.querySelectorAll('nav');

      if (window.pageYOffset > 40) {
        this.bcolorNav = 'rgb(0, 0, 0, 0.8)';
      } else {
        this.bcolorNav = 'rgb(0, 0, 0, 0.2)';
      }
    }

  constructor(private generalService: GeneralService, private titleService: Title,
              public authenticationService: AuthenticationService, private router: Router) {
    this.getScreenSize();

    this.generalService.getAllDocumentsConditional('t_configurar', 'id_configurar', '==', 'K78XPSWYIduqyir83pD3').subscribe((config: any) => {
      localStorage.setItem('barra', config[0].color_barra);
      localStorage.setItem('letra', config[0].color_letra);
      localStorage.setItem('direccion', config[0].direccion);
      localStorage.setItem('email', config[0].email);
      localStorage.setItem('icono', config[0].icono);
      localStorage.setItem('logo', config[0].logo);
      localStorage.setItem('telefono', config[0].telefono);
      localStorage.setItem('titulo', config[0].titulo);

      if (config[0].icono !== '') {
        $('#appFavicon').attr('href', config[0].icono);
      }
      this.titleService.setTitle(config[0].titulo);
    });
  }

  ngOnInit(): void {
    localStorage.setItem('load', 'N');

    if (localStorage.getItem('login') === 'true') {
      if (localStorage.getItem('web') === 'GP') {
        this.router.navigateByUrl('dashboard');
      }
    } else {
      localStorage.setItem('web', '');
      localStorage.setItem('id_usuario', '');
      localStorage.setItem('login', 'false');
      localStorage.setItem('load', 'N');
      this.router.navigateByUrl('');
    }
  }

  signOut() {
    this.authenticationService.SignOut();
  }
}
