import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-egepme',
  templateUrl: './egepme.component.html',
  styleUrls: ['./egepme.component.scss']
})
export class EgepmeComponent implements OnInit {
  modulo1 = false;
  modulo2 = false;
  modulo3 = false;
  modulo4 = false;
  modulo5 = false;
  modulo6 = false;
  modulo7 = false;
  modulo8 = false;

  constructor() { }

  ngOnInit(): void {
  }

}
