import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable()
export class GeneralService {

  constructor(
    public angularFirestore: AngularFirestore,
  ) { }

  newId() {
    return this.angularFirestore.createId();
  }

  /* get */
  getAllDocuments(resource) {
    return this.angularFirestore.collection(resource).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional(resource, a, b, c) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c)).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  getAllDocumentsByOrderDesc(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a, 'desc')).get().pipe(
      map(actions => actions.docs.map(a => {
        const data = a.data();
        return { ...(data as {}) };
      }))
    );
  }

  /* snapshotChanges */
  scAllDocuments(resource) {
    return this.angularFirestore.collection(resource).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditionalByOrder2(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).orderBy(e)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsByOrder(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsByOrder2(resource, a, b) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a).orderBy(b)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsByOrderDesc(resource, a) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  /* ***************************************** */
  getAllDocumentsByOrder2(resource, a, b) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a).orderBy(b)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsByOrder3(resource, a, b, c) {
    return this.angularFirestore.collection(resource, ref => ref.orderBy(a).orderBy(b).orderBy(c)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional2(resource, a, b, c, d, e, f) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional3(resource, a, b, c, d, e, f, g, h, i) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional4(resource, a, b, c, d, e, f, g, h, i, j, k, l) {
    // tslint:disable-next-line: max-line-length
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).where(j, k, l)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional2ByOrder(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional3ByOrder(resource, a, b, c, d, e, f, g, h, i, j) {
    // tslint:disable-next-line: max-line-length
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).orderBy(j)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional3ByOrder2(resource, a, b, c, d, e, f, g, h, i, j, k) {
    // tslint:disable-next-line: max-line-length
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).orderBy(j).orderBy(k)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrderLimit(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).limit(e)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalLimit(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).limit(d)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrderDescLimit(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d, 'desc').limit(e)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditional4ByOrderDescLimit(resource, a, b, c, d, e, f, g, h, i, j, k, l, m, n) {
    // tslint:disable-next-line: max-line-length
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).where(g, h, i).where(j, k, l).orderBy(m, 'desc').limit(n)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrderDesc(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  scAllDocumentsConditional2ByOrderDesc(resource, a, b, c, d, e, f, g) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).where(d, e, f).orderBy(g, 'desc')).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrder(resource, a, b, c, d) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrder2(resource, a, b, c, d, e) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).orderBy(e)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getAllDocumentsConditionalByOrder3(resource, a, b, c, d, e, f) {
    return this.angularFirestore.collection(resource, ref => ref.where(a, b, c).orderBy(d).orderBy(e).orderBy(f)).snapshotChanges().pipe(
      map(actions => actions.map(act => {
        const data = act.payload.doc.data();
        const id = act.payload.doc.id;
        return { id, ...(data as {}) };
      }))
    );
  }

  getDocument(resource, id) {
    return this.angularFirestore.collection(resource).doc(id).snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data();
        const idA = action.payload.id;
        return { idA, ...(data as {}) };
      }));
  }

  editDocument(resource, id, object) {
    return this.angularFirestore.collection(resource).doc(id).set(
      object
    );
  }

  updateDocument(resource, id, object) {
    return this.angularFirestore.collection(resource).doc(id).update(
      object
    );
  }

  newDocument(object, name) {
    return this.angularFirestore.collection(name).add(
      object
    );
  }

  newDocumentWithId(object, name, id) {
    return this.angularFirestore.collection(name).doc(id).set(
      object
    );
  }

  deleteDocument(name, id) {
    return this.angularFirestore.collection(name).doc(id).delete();
  }
}
