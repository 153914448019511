<p><mat-icon (click)="cerrar()" style="float: right; cursor: pointer;">highlight_off</mat-icon></p>
<h1 mat-dialog-title style="text-align: center;">CONSULTA</h1>
<div mat-dialog-content>
  <div *ngIf="dialog.tbl === 'VI'">
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Fecha</div>
      <div class="col-8">{{ consulta.fecha_crea | date:'dd-MM-yyyy HH:mm' }}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Nombre</div>
      <div class="col-8">{{consulta.nombre}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Mail</div>
      <div class="col-8">{{consulta.email}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Teléfono</div>
      <div class="col-8">{{consulta.telefono}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Asunto</div>
      <div class="col-8">{{consulta.asunto}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Mensaje</div>
      <div class="col-8">{{consulta.mensaje}}</div>
    </div>
    <div class="row" style="margin: 0 auto;">
      <div class="col-4">Estado</div>
      <div class="col-8">{{consulta.estado}}</div>
    </div>
    <h3 style="margin-bottom: 2%; color: forestgreen">Seguimiento</h3>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="text-align: center;">
        <ng-container matColumnDef="fecha">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold; width: 20%;"> Fecha </th>
            <td mat-cell *matCellDef="let element"> {{element.fecha_crea | date:'dd-MM-yyyy HH:mm'}} </td>
        </ng-container>
        <ng-container matColumnDef="observacion">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold; width: 60%;"> Observación </th>
            <td mat-cell *matCellDef="let element"> {{element.observacion}} </td>
        </ng-container>
        <ng-container matColumnDef="estado">
            <th mat-header-cell *matHeaderCellDef style="background-color: #FBB03B; text-align: center; color: white; font-weight: bold; width: 20%;">Estado</th>
            <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div *ngIf="dialog.tbl === 'AI'">
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtObservacion" style="background-color: white; border: none;">Observacion</span>
      </div>
      <textarea class="form-control" rows="5" aria-label="Sizing example input" aria-describedby="txtObservacion" [(ngModel)]="seguimiento.observacion"></textarea>
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtRegion" style="background-color: white; border: none;">Estado</span>
      </div>
      <select class="custom-select" id="cbRegion" style="width: 70%;" placeholder="Seleccionar" [(ngModel)]="seguimiento.estado">
        <option value="" selected>Seleccionar</option>
        <option value="G">Gestionando</option>
        <option value="F">Finalizado</option>
      </select>
    </div>
  </div>
</div>
<div mat-dialog-actions>
    <button *ngIf="dialog.tbl === 'AI'" mat-button style="background-color: #D4691F; color: white; margin: 0 auto;" (click)="actualizar()">Actualizar</button>
</div>
