import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '../../servicio/storage.service';

@Component({
  selector: 'app-configurar',
  templateUrl: './configurar.component.html',
  styleUrls: ['./configurar.component.scss']
})
export class ConfigurarComponent implements OnInit {
  configuracion = {
    color_barra: '',
    color_boton: '',
    color_letra: '',
    contacto: '',
    facebook: '',
    icono: '',
    id_configuracion: '',
    logo: '',
    texto: '',
    titulo: '',
    whatsapp: ''
  };
  archivoL: FileList;
  archivoI: FileList;
  urlLogo;
  urlIcono;

  public respArchivoEnv;
  public resultadoCarga;

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              private dashboard: DashboardComponent, private snackBar: MatSnackBar, private storage: StorageService) { }

  ngOnInit(): void {
    localStorage.setItem('load', 'S');

    // tslint:disable-next-line: max-line-length
    this.generalService.getAllDocuments('t_configurar').subscribe((configuracion: any) => {
      this.configuracion = configuracion[0];
      this.urlLogo = this.configuracion.logo;
      this.urlIcono = this.configuracion.icono;
      localStorage.setItem('load', 'N');
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  public changeColor(tipo, color: string) {
    if (tipo === 'B') {
      this.configuracion.color_barra = color;
    } else {
      if (tipo === 'L') {
        this.configuracion.color_letra = color;
      } else {
        this.configuracion.color_boton = color;
      }
    }
  }

  configurar() {
    if (this.archivoI === undefined && this.archivoL === undefined) {
      const objSL = {
        color_barra: this.configuracion.color_barra,
        color_boton: this.configuracion.color_boton,
        color_letra: this.configuracion.color_letra,
        facebook: this.configuracion.facebook,
        texto: this.configuracion.texto,
        titulo: this.configuracion.titulo,
        whatsapp: this.configuracion.whatsapp
      };
      this.generalService.updateDocument('t_configurar', 'C6YCfzW1WKozPmiIkwI5', objSL).then(success => {
        localStorage.setItem('barra', this.configuracion.color_barra);
        localStorage.setItem('boton', this.configuracion.color_boton);
        localStorage.setItem('letra', this.configuracion.color_letra);
        localStorage.setItem('facebook', this.configuracion.facebook);
        localStorage.setItem('titulo', this.configuracion.titulo);
        localStorage.setItem('whatsapp', this.configuracion.whatsapp);
        this.openSnackBar('La configuración ha sido guardada.', 'Configurar');
      });
    }

    if (this.archivoI !== undefined && this.archivoL === undefined) {
      this.storage.cargarWeb('I', this.archivoI[0]).subscribe(
        response => {
          this.respArchivoEnv = response; 
          if (response <= 1) {
            console.log("Error en el servidor"); 
          } else {
            if (this.respArchivoEnv.code == 200 && this.respArchivoEnv.status == "success") {
              this.resultadoCarga = 1;
              const objCL = {
                color_barra: this.configuracion.color_barra,
                color_boton: this.configuracion.color_boton,
                color_letra: this.configuracion.color_letra,
                facebook: this.configuracion.facebook,
                icono: 'https://web.herqui.cl/' + this.respArchivoEnv.msj,
                texto: this.configuracion.texto,
                titulo: this.configuracion.titulo,
                whatsapp: this.configuracion.whatsapp
              };
              this.generalService.updateDocument('t_configurar', 'C6YCfzW1WKozPmiIkwI5', objCL).then(success => {
                localStorage.setItem('barra', this.configuracion.color_barra);
                localStorage.setItem('boton', this.configuracion.color_boton);
                localStorage.setItem('letra', this.configuracion.color_letra);
                localStorage.setItem('facebook', this.configuracion.facebook);
                localStorage.setItem('icono', 'https://web.herqui.cl/' + this.respArchivoEnv.msj);
                localStorage.setItem('titulo', this.configuracion.titulo);
                localStorage.setItem('whatsapp', this.configuracion.whatsapp);
                this.openSnackBar('La configuración ha sido guardada.', 'Configurar');
              });
            } else {
              this.resultadoCarga = 2;
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }

    if (this.archivoI === undefined && this.archivoL !== undefined) {
      this.storage.cargarWeb('L', this.archivoL[0]).subscribe(
        response => {
          this.respArchivoEnv = response; 
          if (response <= 1) {
            console.log("Error en el servidor"); 
          } else {
            if (this.respArchivoEnv.code == 200 && this.respArchivoEnv.status == "success") {
              this.resultadoCarga = 1;
              const objCL = {
                color_barra: this.configuracion.color_barra,
                color_boton: this.configuracion.color_boton,
                color_letra: this.configuracion.color_letra,
                facebook: this.configuracion.facebook,
                logo: 'https://web.herqui.cl/' + this.respArchivoEnv.msj,
                texto: this.configuracion.texto,
                titulo: this.configuracion.titulo,
                whatsapp: this.configuracion.whatsapp
              };
              this.generalService.updateDocument('t_configurar', 'C6YCfzW1WKozPmiIkwI5', objCL).then(success => {
                localStorage.setItem('barra', this.configuracion.color_barra);
                localStorage.setItem('boton', this.configuracion.color_boton);
                localStorage.setItem('letra', this.configuracion.color_letra);
                localStorage.setItem('facebook', this.configuracion.facebook);
                localStorage.setItem('titulo', this.configuracion.titulo);
                localStorage.setItem('whatsapp', this.configuracion.whatsapp);
                localStorage.setItem('logo', 'https://web.herqui.cl/' + this.respArchivoEnv.msj);
                this.openSnackBar('La configuración ha sido guardada.', 'Configurar');
              });
            } else {
              this.resultadoCarga = 2;
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }

    if (this.archivoI !== undefined && this.archivoL !== undefined) {
      this.storage.cargarWeb('I', this.archivoI[0]).subscribe(
        response => {
          this.respArchivoEnv = response; 
          if (response <= 1) {
            console.log("Error en el servidor"); 
          } else {
            if (this.respArchivoEnv.code == 200 && this.respArchivoEnv.status == "success") {
              this.resultadoCarga = 1;
              const objCL = {
                color_barra: this.configuracion.color_barra,
                color_boton: this.configuracion.color_boton,
                color_letra: this.configuracion.color_letra,
                facebook: this.configuracion.facebook,
                icono: 'https://web.herqui.cl/' + this.respArchivoEnv.msj,
                texto: this.configuracion.texto,
                titulo: this.configuracion.titulo,
                whatsapp: this.configuracion.whatsapp
              };
              this.generalService.updateDocument('t_configurar', 'C6YCfzW1WKozPmiIkwI5', objCL).then(success => {
                localStorage.setItem('barra', this.configuracion.color_barra);
                localStorage.setItem('boton', this.configuracion.color_boton);
                localStorage.setItem('letra', this.configuracion.color_letra);
                localStorage.setItem('facebook', this.configuracion.facebook);
                localStorage.setItem('icono', 'https://web.herqui.cl/' + this.respArchivoEnv.msj);
                localStorage.setItem('titulo', this.configuracion.titulo);
                localStorage.setItem('whatsapp', this.configuracion.whatsapp);

                this.storage.cargarWeb('L', this.archivoL[0]).subscribe(
                  response => {
                    this.respArchivoEnv = response; 
                    if (response <= 1) {
                      console.log("Error en el servidor"); 
                    } else {
                      if (this.respArchivoEnv.code == 200 && this.respArchivoEnv.status == "success") {
                        this.resultadoCarga = 1;
                        const objCL = {
                          logo: 'https://web.herqui.cl/' + this.respArchivoEnv.msj
                        };
                        this.generalService.updateDocument('t_configurar', 'VmuUHQTS1ALXTIWKa0nw', objCL).then(success => {
                          localStorage.setItem('logo', 'https://web.herqui.cl/' + this.respArchivoEnv.msj);
                          this.openSnackBar('La configuración ha sido guardada.', 'Configurar');
                        });
                      } else {
                        this.resultadoCarga = 2;
                      }
                    }
                  },
                  error => {
                    console.log(<any>error);
                  }
                );
              });
            } else {
              this.resultadoCarga = 2;
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  logo(tipo, files: FileList) {
    if (tipo === 'L') {
      this.archivoL = files;

      if (this.archivoL.length > 0) {
        const fileL = this.archivoL[0];
        const reader = new FileReader();
    
        reader.onload = e => this.urlLogo = reader.result;
        reader.readAsDataURL(fileL);
      } else {
        this.urlLogo = localStorage.getItem('logo1');
        this.archivoL = undefined;
      }
    } else {
      this.archivoI = files;

      if (this.archivoI.length > 0) {
        const fileI = this.archivoI[0];
        const reader = new FileReader();

        reader.onload = e => this.urlIcono = reader.result;
        reader.readAsDataURL(fileI);
      } else {
        this.urlIcono = localStorage.getItem('icono');
        this.archivoI = undefined;
      }
    }
  }
}
