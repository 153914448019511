import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../servicio/general.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  slide = '';
  slides = [];

  constructor(private generalService: GeneralService) {
    this.generalService.getAllDocumentsConditionalByOrder('t_slide', 'estado', '==', true, 'orden').subscribe((datSlide: any) => {
      if (datSlide.length > 1) {
        let cantS = 1;

        datSlide.forEach((slide: any) => {
          if (cantS > 1) {
            this.slides.push(slide.url_imagen);
          } else {
            this.slide = slide.url_imagen;
          }
          cantS++;
        });
      } else {
        this.slide = datSlide[0].url_imagen;
      }
    });
  }

  ngOnInit(): void {
  }
}
