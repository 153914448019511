<div class="container" style="margin-top: 130px;">
    <div style="margin-top: 2%; margin-bottom: 2%; width: 100%; color: rgb(22, 62, 85)"><mat-icon (click)="volver()" title="Volver" style="cursor: pointer; font-size: 35px;">reply</mat-icon><h2 style="margin-left: 45%; margin-top: -35px;">CONFIGURAR</h2></div>
    <div class="row justify-content-around">
      <div class="col"><h4 style="width: 100%; text-align: center;">Logo</h4></div>
      <div class="col"><h4 style="width: 100%; text-align: center;">Icono</h4></div>
    </div>
    <div class="row">
      <div class="col text-center">
        <div *ngIf="this.urlLogo !== ''">
            <img src="{{this.urlLogo}}" style="width: 200px; height: 200px;">
        </div>
      </div>
      <div class="col text-center" style="margin-top: 75px;">
        <div *ngIf="this.urlIcono !== ''">
          <img src="{{this.urlIcono}}" style="width: 50px; height: 50px;">
      </div>
      </div>
    </div>
    <div class="row" style="margin-top: 10px;">
      <div class="col">
        <div style="text-align: center;">
          <input type="file" style="margin-bottom: 20px;" (change)="logo('L', $event.target.files)">
        </div>
      </div>
      <div class="col">
        <div style="text-align: center;">
          <input type="file" style="margin-bottom: 20px;" (change)="logo('I', $event.target.files)">
        </div>
      </div>
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtNombre" style="background-color: white; border: none;">Nombre</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtNombre" [(ngModel)]="configuracion.titulo">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtWhatsApp" style="background-color: white; border: none;">WhatsApp</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtWhatsApp" [(ngModel)]="configuracion.whatsapp">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtFacebook" style="background-color: white; border: none;">URL Facebook</span>
      </div>
      <input type="text" class="form-control" aria-label="Sizing example input" aria-describedby="txtFacebook" [(ngModel)]="configuracion.facebook">
    </div>
    <div class="input-group input-group-sm mb-3">
      <div class="input-group-prepend" style="width: 30%;">
        <span class="input-group-text" id="txtTexto" style="background-color: white; border: none;">Texto Home</span>
      </div>
      <textarea class="form-control" rows="5" aria-label="Sizing example input" aria-describedby="txtTexto" [(ngModel)]="configuracion.texto"></textarea>
    </div>
    <div class="input-group input-group-sm mb-3">
        <div class="input-group-prepend" style="width: 20%;">
          <span class="input-group-text" id="txtColorB" style="background-color: white; border: none;">Color encabezado</span>
        </div>
        <input aria-describedby="txtColorB" [style.background]="configuracion.color_barra" [(colorPicker)]="configuracion.color_barra" (colorPickerChange)="changeColor('B', $event)" style="cursor: pointer"/>
        <div class="input-group-prepend" style="width: 20%;">
          <span class="input-group-text" id="txtColorL" style="background-color: white; border: none;">Color letra</span>
        </div>
        <input aria-describedby="txtColorL" [style.background]="configuracion.color_letra" [(colorPicker)]="configuracion.color_letra" (colorPickerChange)="changeColor('L', $event)" style="cursor: pointer"/>
    </div>
    <button mat-button style="margin: 0 auto; color: white; background-color: rgb(22, 62, 85);" (click)="configurar()">Configurar</button>
</div>
