import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { GeneralService } from './general.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userData: Observable<firebase.User>;

  constructor(private generalService: GeneralService,
              private angularFireAuth: AngularFireAuth,
              private router: Router,
              private _snackBar: MatSnackBar) {
    this.userData = angularFireAuth.authState;
  }

  openSnackBar(message: string, action: string) {
    localStorage.setItem('load', 'N');
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  SignUp(email: string, password: string) {
    this.angularFireAuth
      .auth
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        console.log('Successfully signed up!', res);
      })
      .catch(error => {
        console.log('Something is wrong:', error.message);
      });
  }

  SignIn(email: string, password: string) {
    localStorage.setItem('web', '');
    localStorage.setItem('id_usuario', '');
    localStorage.setItem('nombre', '');
    localStorage.setItem('login', 'false');
    localStorage.setItem('load', 'N');

    if (email !== '' && password !== '') {
      this.angularFireAuth.auth.signInWithEmailAndPassword(email, password).then(
        res => {
          localStorage.setItem('web', 'GP');
          localStorage.setItem('login', 'true');
          localStorage.setItem('load', 'N');

          this.generalService.getAllDocumentsConditional('t_usuario', 'email', '==', email).subscribe((usuario: any) => {
            if (usuario[0].id_perfil === 'atHP6fYaizDzdTH7aBTF' || usuario[0].id_perfil === 'pX2KiEgTm1SuIUWjDJOL') {
              localStorage.setItem('id_usuario', usuario[0].id_usuario);
              localStorage.setItem('nombre', usuario[0].nombre + ' ' + usuario[0].apellido);

              this.router.navigateByUrl('dashboard');
            } else {
              localStorage.setItem('login', 'false');

              this.openSnackBar('No cuenta con el permiso para ingresar...', 'Error LogIn');
            }
          });
        }
      )
      .catch(Error => {
        localStorage.setItem('login', 'false');

        this.openSnackBar('Correo electrónico o contraseña incorrecta, intente nuevamente...', 'Error LogIn');
      });
    } else {
      if (email === '' && password === '') {
        this.openSnackBar('Debe ingresar el correo electrónico y la contraseña, intente nuevamente...', 'Error LogIn');
      } else {
        if (email === '') {
          this.openSnackBar('Debe ingresar el correo electrónico, intente nuevamente...', 'Error LogIn');
        } else {
          this.openSnackBar('Debe ingresar la contraseña, intente nuevamente...', 'Error LogIn');
        }
      }
    }
  }

  SignOut() {
    localStorage.setItem('web', '');
    localStorage.setItem('id_usuario', '');
    localStorage.setItem('nombre', '');
    localStorage.setItem('login', 'false');
    localStorage.setItem('load', 'N');

    this.angularFireAuth.auth.signOut();
    this.router.navigateByUrl('');
  }

  LogIn(param) {
    this.loggedIn.next(true);
    this.router.navigate([param]);
  }

  public localStorageItem(id: string): string {
    return localStorage.getItem(id);
  }
}
