import { Component, OnInit, Inject } from '@angular/core';
import { GeneralService } from '../../servicio/general.service';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../../servicio/authentication.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface Dialogo {
  tbl: string;
  idC: string;
}

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class ConsultaComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['fecha', 'asunto', 'nombre', 'telefono', 'estado', 'accion'];
  agnoAct = new Date().getFullYear();
  mesAct = new Date().getMonth() + 1;
  meses = [{num: 1, mes: 'Enero'}, {num: 2, mes: 'Febrero'}, {num: 3, mes: 'Marzo'}, {num: 4, mes: 'Abril'},
           {num: 5, mes: 'Mayo'}, {num: 6, mes: 'Junio'}, {num: 7, mes: 'Julio'}, {num: 8, mes: 'Agosto'},
           {num: 9, mes: 'Septiembre'}, {num: 10, mes: 'Octubre'}, {num: 11, mes: 'Noviembre'}, {num: 12, mes: 'Diciembre'}];
           
  constructor(private generalService: GeneralService, private router: Router, private dashboard: DashboardComponent,
              public authenticationService: AuthenticationService, public dialog: MatDialog) { }

  dialogo(tabla, id) {
    this.dialog.open(DialogoConsulta, {
      width: '80%',
      data: {tbl: tabla, idC: id}
    })
  }

  ngOnInit(): void {
    this.buscar();
  }

  buscar() {
    localStorage.setItem('load', 'S');

    let fechaI;
    let fechaF;
    let mesA = +this.mesAct;
    const ultDia = new Date(this.agnoAct, mesA, 0).getDate();

    if (localStorage.getItem('navegador') === 'Apple Computer, Inc.') {
      fechaI = new Date(this.agnoAct + '-' + mesA + '-01' + 'T00:00:00').getTime();
      fechaF = new Date(this.agnoAct + '-' + mesA + '-' + ultDia + 'T23:59:59').getTime();
    } else {
      fechaI = new Date(this.agnoAct + '-' + mesA + '-01' + ' 00:00:00').getTime();
      fechaF = new Date(this.agnoAct + '-' + mesA + '-' + ultDia + ' 23:59:59').getTime();
    }

    this.generalService.scAllDocumentsConditional2ByOrderDesc('t_consulta', 'fecha_crea', '>=', fechaI, 'fecha_crea', '<=', fechaF, 'fecha_crea').subscribe((consultas: any) => {
      consultas.forEach((consulta: any) => {
        switch (consulta.estado) {
          case 'N':
            consulta.estado = 'No leida';
            break;
          case 'R':
            consulta.estado = 'Recibida';
            break;
          case 'G':
            consulta.estado = 'Gestionando';
            break;
          case 'F':
            consulta.estado = 'Finalizada';
            break;
        }
      });
      this.dataSource = new MatTableDataSource(consultas);
      localStorage.setItem('load', 'N');
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
  styleUrls: ['./consulta.component.scss']
})
export class DialogoConsulta {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['fecha', 'observacion', 'estado'];
  txtFecha = 0;

  consulta = {
    asunto: '',
    email: '',
    estado: true,
    fecha_crea: new Date().getTime(),
    id_consulta: '',
    mensaje: '',
    nombre: '',
    telefono: ''
  };

  seguimiento = {
    estado: '',
    fecha_crea: new Date().getTime(),
    id_consulta: '',
    id_consulta_seg: '',
    id_usuario: localStorage.getItem('id_usuario'),
    observacion: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoConsulta>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (this.dialog.tbl === 'VI') {
      this.generalService.getAllDocumentsConditional('t_consulta', 'id_consulta', '==', this.dialog.idC).subscribe((consulta: any) => {
        switch (consulta[0].estado) {
          case 'N':
            consulta[0].estado = 'No leida';
            this.seguimiento.id_consulta = this.dialog.idC;
            this.seguimiento.estado = 'R';
            this.seguimiento.observacion = 'La consulta fue leida';
            this.generalService.newDocument(this.seguimiento, 't_consulta_seg').then(successI => {
              const update = {
                id_consulta_seg: successI.id
              };
              this.generalService.updateDocument('t_consulta_seg', successI.id, update).then(successU => {
                const update2 = {
                  estado: 'R'
                };
                this.generalService.updateDocument('t_consulta', this.dialog.idC, update2).then(successU2 => {})
              });
            });
            break;
          case 'R':
            consulta[0].estado = 'Recibida';
            break;
          case 'G':
            consulta[0].estado = 'Gestionando';
            break;
          case 'F':
            consulta[0].estado = 'Finalizada';
            break;
        }
        this.consulta = consulta[0];
      });

      this.generalService.getAllDocumentsByOrderDesc('t_consulta_seg', 'fecha_crea').subscribe((seguimientos: any) => {
        seguimientos.forEach((seguimiento: any) => {
          switch (seguimiento.estado) {
            case 'N':
              seguimiento.estado = 'No leida';
              break;
            case 'R':
              seguimiento.estado = 'Recibida';
              break;
            case 'G':
              seguimiento.estado = 'Gestionando';
              break;
            case 'F':
              seguimiento.estado = 'Finalizada';
              break;
          }
        });
        this.dataSource = new MatTableDataSource(seguimientos);
      });
    }

    if (this.dialog.tbl === 'AI') {
      this.seguimiento.id_consulta = this.dialog.idC;
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  actualizar() {
    this.generalService.newDocument(this.seguimiento, 't_consulta_seg').then(successI => {
      const update = {
        id_consulta_seg: successI.id
      };
      this.generalService.updateDocument('t_consulta_seg', successI.id, update).then(successU => {
        const update2 = {
          estado: this.seguimiento.estado
        };
        this.generalService.updateDocument('t_consulta', this.seguimiento.id_consulta, update2).then(successU2 => {
          this.openSnackBar('El seguimiento se ha registrado correctamente.', 'Seguimiento consulta');
          this.dialogo.close();
        });
      });
    });
  }
}
