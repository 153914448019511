<footer style="background-color: black; color: white; padding-top: 50px; padding-bottom: 50px;">
    <div class="row">
        <div class="col">
            <ul class="list-group" style="width: 70%; margin-left: 30%; font-size: 12px;">
                <li class="list-group-item" style="border: none; background-color: black;"><label style="cursor: pointer;" routerLink="">INICIO</label></li>
                <li class="list-group-item" style="border: none; background-color: black;"><label style="cursor: pointer;" routerLink="nosotros">NOSOTROS</label></li>
                <li class="list-group-item" style="border: none; background-color: black;"><label style="cursor: pointer;" routerLink="servicios">SERVICIOS</label></li>
                <li class="list-group-item" style="border: none; background-color: black;"><label style="cursor: pointer;" routerLink="egepme">eGe PME</label></li>
                <li class="list-group-item" style="border: none; background-color: black;"><label style="cursor: pointer;" routerLink="contacto">CONTACTO</label></li>
                <li class="list-group-item" style="border: none; background-color: black;"><label style="cursor: pointer;">LOGIN</label></li>
            </ul>
        </div>
        <div class="col"><img class="rounded mx-auto d-block" src="{{authenticationService.localStorageItem('logo')}}" alt="" /></div>
        <div class="col">
            <ul class="list-group" style="font-size: 12px;">
                <li class="list-group-item" style="border: none; background-color: black; font-size: 14px; font-weight: bold;">CONTÁCTANOS</li>
                <li class="list-group-item" style="border: none; background-color: black;"><mat-icon style="color: #D25B33; font-size: 18px;">home</mat-icon>&nbsp;&nbsp;{{authenticationService.localStorageItem('direccion')}}</li>
                <li class="list-group-item" style="border: none; background-color: black;"><mat-icon style="color: #D25B33; font-size: 18px;">phone</mat-icon>&nbsp;&nbsp;{{authenticationService.localStorageItem('telefono')}}</li>
                <li class="list-group-item" style="border: none; background-color: black;"><mat-icon style="color: #D25B33; font-size: 18px;">email</mat-icon>&nbsp;&nbsp;{{authenticationService.localStorageItem('email')}}</li>
            </ul>
        </div>
    </div>
</footer>
