<div class="w-100" style="position: relative">
    <img class="img-fluid" src="../../../assets/img/servicios.jpg" alt="" style="max-height: 600px; width: 100%;" />
    <h2 style="position: absolute; color: white; top: 30%; left: 40%;">SERVICIOS / PRODUCTOS</h2>
</div>
<div style="width: 80%; margin-left: 10%; text-align: center; color: #54595F; font-size: 20px; padding-top: 50px; padding-bottom: 10px; margin-bottom: 30px;">
    <div class="row" style="margin-bottom: 20px;">
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP1.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">GESTIÓN ESTRATÉGICA PARA LA EDUCACIÓN</p>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP2.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">DESARROLLO DE SOLUCIONES INFORMÁTICAS</p>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP3.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">DESARROLLO DE PROYECTOS</p>
        </div>
    </div>
    <div class="row" style="margin-bottom: 20px;">
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP4.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">CONTROL DE GESTIÓN Y NORMATIVA</p>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP5.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">INTEGRACIÓN Y MINERÍA DE DATOS</p>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP6.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">REPORTES Y ANÁLISIS</p>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP7.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">COACHING Y CAPACITACIÓN</p>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP8.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">PROCESOS Y ESTUDIOS DE RRHH</p>
        </div>
        <div class="col">
            <img class="rounded mx-auto d-block" src="../../../assets/img/GP9.png" alt="" />
            <p style="width: 70%; margin-left: 15%;">HEAD HUNTING, SELECCIÓN DE PERSONAL PARA LA EDUCACIÓN</p>
        </div>
    </div>
</div>
<app-pie></app-pie>
