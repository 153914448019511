<div class="w-100" style="position: relative">
    <img class="img-fluid" src="../../../assets/img/egepme.jpg" alt="" style="max-height: 600px; width: 100%;" />
    <div style="position: absolute; top: 30%; left: 40%; background-color: rgb(0, 0, 0, 0.4); padding: 10px;"><img class="img-fluid" src="../../../assets/img/logo_egepme.png" alt="" /></div>
</div>
<div class="w-100" style="text-align: justify; padding-top: 80px; padding-bottom: 80px; color: white; background-color: #54595F">
    <h2 style="margin-bottom: 40px; text-align: center;">PLATAFORMA eGePME</h2>
    <p style="margin-left: 15%; width: 70%;">A partir de los lineamientos de los modelos de gestión, los estándares indicativos de
        desempeño, el marco de la buena enseñanza y de la buena dirección, se ha construido esta plataforma informática, con una
        lógica que permite alcanzar resultados en todos los ámbitos de los Planes de Mejoramiento Educativo (PME), mediante el
        seguimiento y monitoreo permanente de buenas prácticas, percepción de actores de la comunidad escolar y resultados parciales
        y globales.<br><br><br>
        Entendiendo el PME como un eje articulador de la gestión educacional, en la plataforma EduKpi se integra de una manera
        armónica el PEI, el PME con sus objetivos, metas, estrategias, acciones e indicadores de resultados, junto con  los planes
        establecidos por la normativa ministerial, y las distintas subvenciones escolares.</p>
</div>
<div class="w-100" style="padding-top: 50px; padding-bottom: 10px; color: #2B3861;">
    <img class="rounded mx-auto d-block" src="../../../assets/img/logo_egepme_2.png" alt="" />
    <div style="margin-top: 20px; margin-left: 10%; width: 80%; background-image: linear-gradient(#E5E5E5, #F5F5F5); padding-top: 20px; padding-bottom: 20px;">
        <h4 style="text-align: center;">MÓDULOS</h4>
        <div id="accordion" style="margin-left: 2%; width: 96%; margin-top: 20px;">
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloUno">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo1">add</mat-icon>
                        <mat-icon *ngIf="modulo1">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickUno" aria-expanded="false" aria-controls="clickUno" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo1 = !modulo1">Módulo de monitoreo PME</label>
                    </h5>
                </div>
                <div id="clickUno" class="collapse" aria-labelledby="moduloUno" data-parent="#accordion">
                    <div class="card-body">
                        Herramienta metodológica informática  que integra y monitorea el PEI, el PME, los distintos planes normativos y subvenciones,
                        para la mejora de resultados, entrega dashboard de resultados por indicadores emitiendo alarmas ante atrasos de cumplimiento.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloDos">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo2">add</mat-icon>
                        <mat-icon *ngIf="modulo2">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickDos" aria-expanded="false" aria-controls="clickDos" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo2 = !modulo2">Acompañamiento de Aula</label>
                    </h5>
                </div>
                <div id="clickDos" class="collapse" aria-labelledby="moduloDos" data-parent="#accordion">
                    <div class="card-body">
                        Solución informática que cuenta con una pauta flexible de acompañamiento y supervisión de procesos de aula. Permite guardar
                        las diversas observaciones realizadas al docente y entrega gráficas de evolución y cumplimiento de metas.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloTres">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo3">add</mat-icon>
                        <mat-icon *ngIf="modulo3">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickTres" aria-expanded="false" aria-controls="clickTres" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo3 = !modulo3">Módulo Encuestas</label>
                    </h5>
                </div>
                <div id="clickTres" class="collapse" aria-labelledby="moduloTres" data-parent="#accordion">
                    <div class="card-body">
                        Solución informática que permite contestar encuestas en línea, calcular sus resultados por variable, entrega informe de
                        resultados cuantitativos y gráficos.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloCuatro">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo4">add</mat-icon>
                        <mat-icon *ngIf="modulo4">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickCuatro" aria-expanded="false" aria-controls="clickCuatro" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo4 = !modulo4">Módulo Preventivo conductual</label>
                    </h5>
                </div>
                <div id="clickCuatro" class="collapse" aria-labelledby="moduloCuatro" data-parent="#accordion">
                    <div class="card-body">
                        Solución informática basada en diversos cuestionarios predictores de dificultades conductuales escolares, como hiperactividad,
                        baja autoestima, riesgo de hacer o padecer bullyng entre otras.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloCinco">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo5">add</mat-icon>
                        <mat-icon *ngIf="modulo5">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickCinco" aria-expanded="false" aria-controls="clickCinco" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo5 = !modulo5">Módulo Control Presupuestario</label>
                    </h5>
                </div>
                <div id="clickCinco" class="collapse" aria-labelledby="moduloCinco" data-parent="#accordion">
                    <div class="card-body">
                        Herramienta informática que monitorea el gasto de las diversas subvenciones en relación al presupuesto establecido por dimensión,
                        subdimensión y acciones.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloSeis">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo6">add</mat-icon>
                        <mat-icon *ngIf="modulo6">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickSeis" aria-expanded="false" aria-controls="clickSeis" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo6 = !modulo6">Módulo Inventario</label>
                    </h5>
                </div>
                <div id="clickSeis" class="collapse" aria-labelledby="moduloSeis" data-parent="#accordion">
                    <div class="card-body">
                        Herramienta que permite de forma simple codificar e ingresar los diversos bienes del establecimiento, ingresando a un sistema
                        de Almacenamiento. Control de Existencia y Baja de productos.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloSiete">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo7">add</mat-icon>
                        <mat-icon *ngIf="modulo7">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickSiete" aria-expanded="false" aria-controls="clickSiete" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo7 = !modulo7">Módulo Check List Digital</label>
                    </h5>
                </div>
                <div id="clickSiete" class="collapse" aria-labelledby="moduloSiete" data-parent="#accordion">
                    <div class="card-body">
                        Solución informática de alta flexibilidad que permite hacer un  check list de procesos administrativos, de tareas, de
                        asistencia y otros requeridos, entregando dash board  inmediatos para el análisis.
                    </div>
                </div>
            </div>
            <div class="card" style="background-color: #F5F5F5;">
                <div class="card-header" id="moduloOcho">
                    <h5 class="mb-0">
                        <mat-icon *ngIf="!modulo8">add</mat-icon>
                        <mat-icon *ngIf="modulo8">remove</mat-icon>
                        <label type="button" class="btn btn-link collapsed" data-toggle="collapse" data-target="#clickOcho" aria-expanded="false" aria-controls="clickOcho" style="color: #2B3861; font-weight: bold; font-size: 14px;" (click)="modulo8 = !modulo8">Módulo Control de Asistencia (SE)</label>
                    </h5>
                </div>
                <div id="clickOcho" class="collapse" aria-labelledby="moduloOcho" data-parent="#accordion">
                    <div class="card-body">
                        Herramienta informática de fácil aplicación que permite monitorear el control de asistencia de los estudiantes y los
                        patrones de ausentismo, entregando alarmas para actuar preventivamente y evitar la deserción escolar.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row" style="margin-left: 10%; width: 80%; font-size: 16px; font-weight: bold; margin-bottom: 10px;">
    <div class="col">FORMAS DE CONTRATACION</div>
    <div class="col">&nbsp;</div>
    <div class="col" style="text-align: center;">PARTNERS</div>
</div>
<div class="row" style="margin-left: 10%; width: 80%; margin-bottom: 30px;">
    <div class="col"><img class="rounded mx-auto d-block" src="../../../assets/img/convenio_marco.png" alt="" /></div>
    <div class="col"><img class="rounded mx-auto d-block" src="../../../assets/img/subvencionEP.png" alt="" /></div>
    <div class="col"><img class="rounded mx-auto d-block" src="../../../assets/img/holos.png" alt="" /></div>
</div>
<app-pie></app-pie>
