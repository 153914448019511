<div *ngIf="verDashboard" class="container" style="margin-top: 130px;">
    <div class="row">
        <div class="col-sm">
            <h5 style="text-align: center; margin-top: 5%; margin-bottom: 5%;">Intranet Administrador</h5>
            <div class="row" style="margin: 0 auto;">
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/consultas.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%; border: none;" routerLink="consulta" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Contactos</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/configuracion.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%; border: none;" routerLink="configurar" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Configuración</div>
                </div>
            </div>
            <div class="row" style="margin: 0 auto; margin-top: 5%;">
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/slide.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%; border: none;" routerLink="slide" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Slide</div>
                </div>
                <div class="col-sm">
                    <button mat-button style="width: 80%; height: 150px; margin-left: 10%; padding-top: 10%; background-image: url('../assets/img/usuarios.png'); background-repeat: no-repeat;
                    background-position: center; background-size: auto 100%; border: none;" routerLink="usuario" (click)="verDashboard = false"></button>
                    <div style="text-align: center;">Usuarios</div>
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
