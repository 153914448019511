import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './web/home/home.component';
import { NosotrosComponent } from './web/nosotros/nosotros.component';
import { ServiciosComponent } from './web/servicios/servicios.component';
import { EgepmeComponent } from './web/egepme/egepme.component';
import { ContactoComponent } from './web/contacto/contacto.component';
import { LoginComponent } from './intranet/login/login.component';
import { DashboardComponent } from './intranet/dashboard/dashboard.component';
import { ConsultaComponent } from './intranet/consulta/consulta.component';
import { ConfigurarComponent } from './intranet/configurar/configurar.component';
import { SlideComponent } from './intranet/slide/slide.component';
import { UsuarioComponent } from './intranet/usuario/usuario.component';

const routes: Routes = [
  {path : '', component : HomeComponent},
  {path : 'nosotros', component : NosotrosComponent},
  {path : 'servicios', component : ServiciosComponent},
  {path : 'egepme', component : EgepmeComponent},
  {path : 'contacto', component : ContactoComponent},
  {path : 'login', component : LoginComponent},
  {path : 'dashboard', component : DashboardComponent,
  children: [
    {path : 'consulta', component : ConsultaComponent},
    {path : 'configurar', component : ConfigurarComponent},
    {path : 'slide', component : SlideComponent},
    {path : 'usuario', component : UsuarioComponent}
  ]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
