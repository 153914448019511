<div class="row" style="margin-top: 150px;">
    <div class="col">
        <h1 class="text-center" [ngStyle]="{'color': authenticationService.localStorageItem('letra')}" style="font-weight: bold;">CONTACTO</h1>
        <form (ngSubmit)="onSubmit(contactForm, 'Contacto');" #contactForm="ngForm">
        <mat-form-field>
            <input matInput placeholder="Nombre" #nombre maxlength="80" [(ngModel)]="ContactModel.name" name="Name" required>
            <mat-hint align="end">{{nombre.value?.length || 0}}/80</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Email" type="email" [(ngModel)]="ContactModel.email" name="Email" [email]="true" required>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="Asunto" #asunto maxlength="60" [(ngModel)]="ContactModel.subject" name="Subject" required>
            <mat-hint align="end">{{asunto.value?.length || 0}}/60</mat-hint>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput placeholder="Mensaje" #msn maxlength="300" [(ngModel)]="ContactModel.msn" name="Msn" cols="25" required></textarea>
            <mat-hint align="end">{{msn.value?.length || 0}}/300</mat-hint>
        </mat-form-field>
        <button mat-raised-button color="primary" [disabled]="!contactForm.form.valid">Enviar</button>
        </form>
    </div>
    <div class="col" style="text-align: center;">
        <div class="row"><div class="col"><img class="site-logo" src="./../../../assets/img/llamanos.png" alt="Llámanos" /></div></div>
        <div class="row"><div class="col" style="margin-top: 40px; margin-bottom: 40px;">Llámanos...</div></div>
        <div class="row"><div class="col">9 98202407</div></div>
        <div class="row"><div class="col">9 99407872</div></div>
        <div class="row"><div class="col">9 98880585</div></div>
        <div class="row"><div class="col"><img class="site-logo" src="./../../../assets/img/escribenos.png" alt="Escríbenos" /></div></div>
        <div class="row"><div class="col" style="margin-top: 40px; margin-bottom: 40px;">Escríbenos...</div></div>
        <div class="row"><div class="col" style="margin-bottom: 20px;">contacto@grupopiense.com</div></div>
    </div>
</div>
<app-pie></app-pie>
