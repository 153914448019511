<mat-card class="example-card" style="margin-top: 150px;">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image" [ngStyle]="{'background-image': 'url(' + authenticationService.localStorageItem('icono') + ')'}"></div>
      <mat-card-title>{{authenticationService.localStorageItem('titulo')}}</mat-card-title>
      <mat-card-subtitle>Log In</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <input type="email" [(ngModel)]="email" class="form-control" placeholder="Correo electrónico" required>
      <input type="password" [(ngModel)]="password" class="form-control" placeholder="Contraseña" required>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="authenticationService.localStorageItem('load') === 'N'" mat-button style="width: 30%; margin-left: 35%;"  (click)="signIn()">Ingresar</button>
      <div *ngIf="authenticationService.localStorageItem('load') === 'S'" style="width: 30%; margin-left: 35%; text-align: center;">
        <img src="../../assets/img/load_MG.gif" class="rounded mx-auto d-block" style="width: 4rem; height: 4rem; text-align: center;" alt="...">
      </div>
    </mat-card-actions>
</mat-card>
