import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';

import * as firebase from 'firebase/app';

export interface Dialogo {
  dlg: string;
  idD: string;
  tit: string;
}

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class UsuarioComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['nombre', 'apellido', 'email', 'estado', 'accion'];

  constructor(public authenticationService: AuthenticationService, private generalService: GeneralService,
              public dialog: MatDialog, private router: Router, private dashboard: DashboardComponent) { }

  dialogo(dialogo, id): void {
    let titulo = '';

    switch (dialogo) {
      case 'CU':
        titulo = 'Nuevo Usuario';
        break;
      case 'EU':
        titulo = 'Editar Usuario';
        break;
    }
    this.dialog.open(DialogoUsuario, {
      width: '70%',
      data: {dlg: dialogo, idD: id, tit: titulo}
    });
  }

  ngOnInit(): void {
    this.generalService.scAllDocumentsConditionalByOrder2('t_usuario', 'id_perfil', '==', 'pX2KiEgTm1SuIUWjDJOL', 'nombre', 'apellido').subscribe(usuarios => {
      this.dataSource = new MatTableDataSource(usuarios);
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  estado(id, evento) {
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_usuario', id, obj).then(success => { });
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
  styleUrls: ['./usuario.component.scss']
})
export class DialogoUsuario {
  usuario = {
    apellido: '',
    email: '',
    estado: true,
    fecha_crea: firebase.firestore.FieldValue.serverTimestamp(),
    id_perfil: 'pX2KiEgTm1SuIUWjDJOL',
    id_usuario: '',
    nombre: '',
    primera: true,
    rut: ''
  };

  constructor(private generalService: GeneralService, private snackBar: MatSnackBar, public authenticationService: AuthenticationService,
              public dialogo: MatDialogRef<DialogoUsuario>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) {
    if (dialog.dlg === 'EU') {
      this.generalService.getAllDocumentsConditional('t_usuario', 'id_usuario', '==', dialog.idD).subscribe((datDoc: any) => {
        this.usuario = datDoc[0];
      });
    }
  }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  crear() {
    this.usuario.email = this.usuario.email.replace(/\s/g, "");

    this.generalService.getAllDocumentsConditional('t_usuario', 'email', '==', this.usuario.email).subscribe((usuario: any) => {
      if (usuario.length === 0) {
        this.generalService.newDocument(this.usuario, 't_usuario').then(successI => {
          this.usuario.id_usuario = successI.id;
          this.authenticationService.SignUp(this.usuario.email, 'NEW2020PASS');

          const update = {
            id_usuario: this.usuario.id_usuario
          };
          this.generalService.updateDocument('t_usuario', successI.id, update).then(successU2 => {
            this.openSnackBar('El usuario se ha creado correctamente.', 'Nuevo Usuario');
          });
        });
      }
    });
  }

  editar() {
    const update = {
      apellido: this.usuario.apellido,
      nombre: this.usuario.nombre
    };
    this.generalService.updateDocument('t_usuario', this.dialog.idD, update).then(successU2 => {
      this.openSnackBar('El usuario se ha editado correctamente.', 'Editar Usuario');
      this.dialogo.close();
    });
  }
}
