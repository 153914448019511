import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, EmailValidator } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MailForm } from '../../servicio/mailForm';
import { SendEmailService } from '../../servicio/send-email.service';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgForm } from '@angular/forms';
import { GeneralService } from '../../servicio/general.service';
import { AuthenticationService } from '../../servicio/authentication.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  email = new FormControl('', [Validators.required, Validators.email]);
  ContactModel = new MailForm();
  color = '';

  private emailResponse;
  private truefalse: boolean = false;

  constructor(private sendServices: SendEmailService, public snackBar: MatSnackBar,
              private generalService: GeneralService, public authenticationService: AuthenticationService) {
    localStorage.setItem('home', 'N');
    /*this.generalService.getAllDocumentsConditional('t_configuracion', 'id_configuracion', '==', 'VmuUHQTS1ALXTIWKa0nw').subscribe((config: any) => {
      this.color = config[0].color;
    });*/
  }

  ngOnInit() { };

  getErrorMessage() {
    return this.email.hasError('required') ? 'Ingrese un correo electronico' :
      this.email.hasError('email') ? 'Correo invalido' :
        '';
  }

  onSubmit(f: NgForm, tipoF) {
    this.ContactModel.tipo = tipoF;
    this.getSentServices(this.ContactModel, f);
  }

  getSentServices(body: MailForm, f: NgForm) {
    this.sendServices.getResponseEmail(body).subscribe(
      data => {
        if (data) {
          const objN = {
            asunto: this.ContactModel.subject,
            email: this.ContactModel.email,
            estado: 'N',
            fecha_crea: new Date().getTime(),
            id_consulta: '',
            mensaje: this.ContactModel.msn,
            nombre: this.ContactModel.name
          }
          this.generalService.newDocument(objN, 't_consulta').then(successI => {
            const update = {
              id_consulta: successI.id
            };
            this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
              this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
                duration: 5000,
              });
            });
          });
          f.reset();
        } else {
          this.snackBar.open(':(', 'Error', {
            duration: 2000,
          });
        }
      },
      err => { /*this.snackBar.open('Algo fallo :/', 'ups', {
        duration: 5000,
      });*/
      const objN = {
        asunto: this.ContactModel.subject,
        email: this.ContactModel.email,
        estado: 'N',
        fecha_crea: new Date().getTime(),
        id_consulta: '',
        mensaje: this.ContactModel.msn,
        nombre: this.ContactModel.name
      }
      this.generalService.newDocument(objN, 't_consulta').then(successI => {
        const update = {
          id_consulta: successI.id
        };
        this.generalService.updateDocument('t_consulta', successI.id, update).then(successU => {
          this.snackBar.open('¡Muchas gracias por contactarnos! En menos de 24 hrs te ubicaremos por cielo, mar o tierra.', 'Contacto', {
            duration: 5000,
          });
        });
      });
      f.reset();
    });
  }
}
