import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class StorageService{
    public urlCargaWebI = "https://imagen.grupopiense.com/cargarWebI.php";
    public urlCargaWebL = "https://imagen.grupopiense.com/cargarWebL.php";
    public urlCargaWebS = "https://imagen.grupopiense.com/cargarWebS.php";
    public urlCargaWebT = "https://trabajos.herqui.cl/cargarWebT.php";

	constructor(private http: HttpClient){}

	public cargarWeb(imagen: string, archivoParaSubir: File){
		const formData = new FormData();
		const fecha = new Date();
		const tipo = archivoParaSubir.name.split('.');
		let largo = tipo.length - 1;
        let tipoF = tipo[largo];

        if (imagen === 'I') {
            formData.append('web', archivoParaSubir, fecha.getTime() + '.' + tipoF); 
    
            return this.http.post(this.urlCargaWebI, formData);
        } else {
            if (imagen === 'L') {
                formData.append('web', archivoParaSubir, fecha.getTime() + '.' + tipoF); 
        
                return this.http.post(this.urlCargaWebL, formData);
            } else {
                if (imagen === 'S') {
                    formData.append('slide', archivoParaSubir, fecha.getTime() + '.' + tipoF); 
            
                    return this.http.post(this.urlCargaWebS, formData);
                } else {
                    formData.append('trabajo', archivoParaSubir, fecha.getTime() + '.' + tipoF); 
            
                    return this.http.post(this.urlCargaWebT, formData);
                }
            }
        }
    }
}
