import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../servicio/authentication.service';
import { GeneralService } from '../../servicio/general.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '../../servicio/storage.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface Dialogo {
  idS: string;
}

@Component({
  selector: 'app-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss']
})
export class SlideComponent implements OnInit {
  slide = {
    estado: true,
    id_slide: '',
    orden: 0,
    url_imagen: ''
  };
  slideIC = [];
  slideImgC = [];
  archivoI: FileList;
  urlImagen;
  verBoton = true;

  public respArchivoEnv;
  public resultadoCarga;

  constructor(private router: Router, public authenticationService: AuthenticationService, private generalService: GeneralService,
              private dashboard: DashboardComponent, private snackBar: MatSnackBar, private storage: StorageService, public dialog: MatDialog) { }

  dialogo(id): void {
    this.dialog.open(DialogoSlide, {
      width: '80%',
      data: {idS: id}
    });
  }

  ngOnInit(): void {
    localStorage.setItem('load', 'S');
    this.urlImagen = '';

    this.generalService.scAllDocumentsByOrder('t_slide', 'orden').subscribe((datSlide: any) => {
      let cant = 1;
      this.slideIC = [];
      this.slideImgC = [];

      if (datSlide.length > 0) {
        datSlide.forEach((dSlide: any) => {
          let objS = {
            estado: dSlide.estado,
            id_slide: dSlide.id_slide,
            url_imagen: dSlide.url_imagen
          }
          this.slideImgC.push(objS);
  
          if (cant === 4) {
            let objSP = {
              slide: this.slideImgC
            }
            this.slideIC.push(objSP);
            this.slideImgC = [];
            cant = 0;
          }
          cant++;
        });

        let objSP = {
          slide: this.slideImgC
        }
        this.slideIC.push(objSP);
      }
    });
  }

  volver() {
    this.dashboard.verDashboard = true;
    this.router.navigateByUrl('dashboard');
  }

  crear() {
    if (this.archivoI !== undefined) {
      this.verBoton = false;

      this.storage.cargarWeb('S', this.archivoI[0]).subscribe(
        response => {
          this.respArchivoEnv = response; 
          if (response <= 1) {
            console.log("Error en el servidor"); 
          } else {
            if (this.respArchivoEnv.code == 200 && this.respArchivoEnv.status == "success") {
              this.generalService.getAllDocumentsByOrderDesc('t_slide', 'orden').subscribe((slide: any) => {
                this.resultadoCarga = 1;
                let numS = 0;

                if (slide.length > 0) {
                  numS = slide[0].orden;
                }
                const objCS = {
                  estado: true,
                  id_slide: this.generalService.newId(),
                  orden: numS + 1,
                  url_imagen: 'https://imagen.grupopiense.com/slide/' + this.respArchivoEnv.msj
                };
                this.generalService.newDocumentWithId(objCS, 't_slide', objCS.id_slide).then(success => {
                  this.verBoton = true;
                  this.urlImagen = '';
                  this.archivoI = undefined;
                  this.openSnackBar('La imagen se ha cargado correctamente.', 'Slide');
                });
              });
            } else {
              this.resultadoCarga = 2;
            }
          }
        },
        error => {
          console.log(<any>error);
        }
      );
    }
  }

  estado(id, evento) {
    const obj = {
      estado: evento.checked
    };
    this.generalService.updateDocument('t_slide', id, obj).then(success => { });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }

  imagen(files: FileList) {
    this.archivoI = files;

    if (this.archivoI.length > 0) {
      const fileI = this.archivoI[0];
      const reader = new FileReader();

      reader.onload = e => this.urlImagen = reader.result;
      reader.readAsDataURL(fileI);
    } else {
      this.urlImagen = '';
      this.archivoI = undefined;
    }
  }
}

@Component({
  selector: 'app-dialogo',
  templateUrl: 'dialogo.component.html',
  styleUrls: ['./slide.component.scss']
})
export class DialogoSlide {
  constructor(private generalService: GeneralService, private snackBar: MatSnackBar,
              public dialogo: MatDialogRef<DialogoSlide>, @Inject(MAT_DIALOG_DATA) public dialog: Dialogo) { }

  cerrar() {
    this.dialogo.close();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 5000,
    });
  }

  eliminar(resp) {
    if (resp === 'S') {
      this.generalService.deleteDocument('t_slide', this.dialog.idS).then(successU => {
        this.dialogo.close();
      });
    } else {
      this.dialogo.close();
    }
  }
}
