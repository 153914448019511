<div id="carouselExampleSlidesOnly" class="carousel slide carousel-fade" data-ride="carousel">
    <div class="carousel-inner">
        <div class="carousel-caption w-100" style="left: 0px; top: 20%;">
            <h2>INNOVACIÓN Y ASESORÍAS EDUCACIONALES</h2>
            <p>CONVERSEMOS</p>
            <button type="button" class="btn btn-outline-dark btn-md btSlide" routerLink="contacto">CONTACTO</button>
        </div>
        <div class="carousel-item active">
            <img class="d-block w-100" src="{{slide}}" alt="" style="height: 700px;"/>
        </div>
        <div *ngFor="let sli of slides" class="carousel-item">
            <img class="d-block w-100" src="{{sli}}" alt="" style="height: 700px;"/>
        </div>
    </div>
</div>
<div class="container-fluid" style="margin-bottom: 80px;">
    <h1 style="color: #2B3861; margin-top: 80px; text-align: center;">NOSOTROS</h1>
    <div style="width: 10%; margin-left: 45%; margin-bottom: 40px; border-top: 1px solid #2B3861;"></div>
    <div class="row" style="margin-left: 10%; margin-right: 10%; margin-bottom: 20px; color: #2B3861; text-align: center; font-size: 14px;">
        <div class="col">
            <img class="d-block w-70" src="https://imagen.grupopiense.com/nosotros_1.png" alt="" style="margin-left: 15%;"/>
            <label style="width: 90%; margin-left: 5%; margin-top: 30px;">Personalizamos soluciones de acuerdo a las necesidades de cada cliente.</label>
        </div>
        <div class="col">
            <img class="d-block w-70" src="https://imagen.grupopiense.com/nosotros_2.png" alt="" style="margin-left: 15%;"/>
            <label style="width: 90%; margin-left: 5%; margin-top: 30px;">Nos involucramos en los procesos de nuestros clientes para entregar una óptima solución.</label>
        </div>
        <div class="col">
            <img class="d-block w-70" src="https://imagen.grupopiense.com/nosotros_3.png" alt="" style="margin-left: 15%;"/>
            <label style="width: 90%; margin-left: 5%; margin-top: 30px;">Visualizamos y estamos atentos a nuevos desafíos que puedan surgir, a través de servicios y productos que agregan valor y atienden necesidades, dejando instaladas nuevas capacidades en nuestros clientes.</label>
        </div>
    </div>
    <div style="width: 100%; text-align: center;"><button type="button" class="btn btn-outline-dark btn-md btMas" routerLink="nosotros">CONÓCENOS MÁS...</button></div>
</div>
<app-pie></app-pie>
